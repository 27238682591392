import {Helmet} from 'react-helmet-async';
import {connect} from 'react-redux';
import {Action} from 'redux';
import React, {Component, ReactElement} from 'react';

import MarketLayout, {reducer} from 'web/components/market_layout';
import {ClientSettings} from 'config/settings';

import {StoreState} from './controller';
import TermsLayout from '../components/terms_layout';

// entire store state is mapped to props
export interface Props extends StoreState {
  // no own props
}

declare const settings: ClientSettings;

class TermsPage extends Component<Props> {
  public static pageName = 'Good Eggs Terms of Use';

  public static reducer(state: StoreState, action: Action): StoreState {
    return reducer(state, action);
  }

  private renderTermsOfUse(): ReactElement {
    return (
      <div>
        <h3>Terms of Use</h3>
        <h4>
          PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES (DEFINED BELOW) ARE SUBJECT TO THE
          FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS
          THE SERVICES IN ANY MANNER.
        </h4>
        <br />
        <p>Effective date: July 2020</p>
        <p>
          Welcome to Good Eggs. Please read on to learn the rules and restrictions that govern your
          use of our website(s) (located at goodeggs.com), products, services and applications (the{' '}
          {'"'}Services{'"'}), including, without limitation, any request to receive information
          about, or to purchase any of the products made available through our website(s) (each, a{' '}
          {'"'}Product{'"'}). If you have any questions, comments, or concerns regarding these terms
          or the Services, please contact us at:
        </p>
        <p>
          Good Eggs
          <br />
          2000 Maritime St.
          <br />
          Oakland, CA 94607
          <br />
          <a className="terms_page__link" href="mailto:help@goodeggs.com">
            help@goodeggs.com
          </a>
          <br />
          1-888-312-1341
        </p>
        <p>
          These Terms of Use (the {'"'}Terms{'"'}) are a binding contract between you and Good Eggs,
          Inc. ({'"'}Good Eggs,{'"'} {'"'}we{'"'} and {'"'}us{'"'}). You must agree to and accept
          all of the Terms, or you don’t have the right to use the Services. Your use of the
          Services in any way means that you agree to all of these Terms, and these Terms will
          remain in effect while you use the Services. These Terms include the provisions in this
          document, as well as those in the{' '}
          <a className="terms_page__link" href={settings.privacyLink}>
            Privacy Policy
          </a>
          ,{' '}
          <a className="terms_page__link" href="/terms/copyright">
            Copyright Dispute Policy
          </a>
          ,{' '}
          <a className="terms_page__link" href="/terms/returns">
            Returns, Refunds, and Credits Policy
          </a>
          ,{' '}
          <a className="terms_page__link" href="/terms/gift-cards">
            Gift Cards Terms of Use
          </a>
          ,{' '}
          <a className="terms_page__link" href="/terms/referral">
            Promotions and Referral Program Terms of Use
          </a>
          ,{' '}
          <a className="terms_page__link" href="/terms/alcohol">
            Alcohol Terms of Use
          </a>
          , and any other relevant policies and any other accompanying terms and conditions of sale
          entered into between you and us for the sale of any Products. Your use of or participation
          in certain Services may also be subject to additional policies, rules and/or conditions (
          {'"'}
          Additional Terms{'"'}), which are incorporated herein by reference, and you understand and
          agree that by using or participating in any such Services, you agree to also comply with
          these Additional Terms.
        </p>
        <p>
          <u>
            <b>
              Please read these Terms carefully. They cover important information about Services
              provided to you and any charges, taxes, and fees we bill you. These Terms include
              information about future changes to these Terms, automatic renewals, limitations of
              liability, a class action waiver and resolution of disputes by arbitration instead of
              in court. PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT TO THE
              FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR
              ACCESS THE SERVICES IN ANY MANNER.
              <br />
              <br />
              ARBITRATION NOTICE AND CLASS ACTION WAIVER: EXCEPT FOR CERTAIN TYPES OF DISPUTES
              DESCRIBED IN THE ARBITRATION AGREEMENT SECTION BELOW, YOU AGREE THAT DISPUTES BETWEEN
              YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR
              RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
            </b>
          </u>
        </p>
        <h4>Will these Terms ever change?</h4>
        <p>
          We are constantly trying to improve our Products and Services, so these Terms may need to
          change along with our Products and Services. We reserve the right to change the Terms at
          any time, but if we do, we will bring it to your attention by placing a notice on the
          https://www.goodeggs.com/ website, by sending you an email, and/or by some other means.
          <br />
          If you don’t agree with the new Terms, you are free to reject them; unfortunately, that
          means you will no longer be able to use the Services. If you use the Services in any way
          after a change to the Terms is effective, that means you agree to all of the changes.
          <br />
          Except for changes by us as described here, no other amendment or modification of these
          Terms will be effective unless in writing and signed by both you and us.
        </p>
        <h4>What about my privacy?</h4>
        <p>
          Good Eggs takes the privacy of its users very seriously. For the current Good Eggs Privacy
          Policy, please click{' '}
          <a className="terms_page__link" href="/terms/privacy">
            here
          </a>
          .
          <br />
          <br />
          The Children’s Online Privacy Protection Act ({'"'}COPPA{'"'}) requires that online
          service providers obtain parental consent before they knowingly collect personally
          identifiable information online from children who are under 13. We do not knowingly
          collect or solicit personally identifiable information from children under 13; if you are
          a child under 13, please do not attempt to register for or otherwise use the Services or
          send any personal information about yourself to us. If we learn we have collected personal
          information from a child under 13, we will delete that information as quickly as possible.
          If you believe that a child under 13 may have provided us personal information, please
          contact us at{' '}
          <a className="terms_page__link" href="mailto:help@goodeggs.com">
            help@goodeggs.com
          </a>
          .
        </p>
        <h4>What are the basics of using Good Eggs?</h4>
        <p>
          You may be required to sign up for an account, and select a password and username ({'"'}
          Good Eggs User ID{'"'}). You promise to provide us, Good Eggs, with complete and updated
          registration information about yourself. You may not select as your Good Eggs User ID a
          name that you don’t have the right to use, or another person’s name with the intent to
          impersonate that person. You may not transfer your account to anyone else without our
          prior written permission.
          <br />
          <br />
          Additionally, you may be able to access certain parts or features of the Services by using
          your account credentials from other services (each, a{'"'}Third Party Account{'"'}), such
          as those offered by Google and Facebook. By using the Services through a Third Party
          Account, you permit us to access certain information from such account for use by the
          Services. You are ultimately in control of how much information is accessible to us and
          may exercise such control by adjusting your privacy settings on your Third Party Account.
          <br />
          <br />
          You represent and warrant that you are of legal age to form a binding contract (or if not,
          you’ve received your parent’s or guardian’s permission to use the Services and gotten your
          parent or guardian to agree to these Terms on your behalf). If you’re agreeing to these
          Terms on behalf of an organization or entity, you represent and warrant that you are
          authorized to agree to these Terms on that organization or entity’s behalf and bind them
          to these Terms (in which case, the references to {'"'}you{'"'} and {'"'}your{'"'} in these
          Terms, except for in this sentence, refer to that organization or entity).
          <br />
          <br />
          You will only use the Services and Products you obtain for your own internal, personal,
          non-commercial use, and not on behalf of or for the benefit of any third party, and only
          in a manner that complies with all laws that apply to you. If your use of the Services is
          prohibited by applicable laws, then you aren’t authorized to use the Services. We can’t
          and won’t be responsible for your using the Services or Products in a way that breaks the
          law.
          <br />
          <br />
          You will not share your account or password with anyone, and you must protect the security
          of your account and your password. You’re responsible for any activity associated with
          your account.
        </p>
        <h4>What about messaging?</h4>
        <p>
          As part of the Services, you may receive communications through the Services, including
          messages that Good Eggs sends you (for example, via email or SMS). When signing up for the
          Services, you will receive a welcome message and instructions on how to stop receiving
          messages. By signing up for the Services and providing us with your wireless number, you
          confirm that you want Good Eggs to send you information that we think may be of interest
          to you, which may include Good Eggs using automated dialing technology to text you at the
          wireless number you provided, and you agree to receive communications from Good Eggs, and
          you represent and warrant that each person you register for the Services or for whom you
          provide a wireless phone number has consented to receive communications from Good Eggs.
          You agree to indemnify and hold Good Eggs harmless from and against any and all claims,
          liabilities, damages (actual and consequential), losses and expenses (including attorneys’
          fees) arising from or in any way related to your breach of the foregoing.
        </p>
        <h4>Are there restrictions in how I can use the Services?</h4>
        <p>
          You represent, warrant, and agree that you will not contribute any Content or User
          Submission (each of those terms is defined below) or otherwise use the Services or
          interact with the Services in a manner that:
          <ol type="a">
            <li>
              Infringes or violates the intellectual property rights or any other rights of anyone
              else (including Good Eggs);
            </li>
            <li> Violates any law or regulation, including any applicable export control laws;</li>
            <li>
              Is harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or
              otherwise objectionable;
            </li>
            <li>
              Jeopardizes the security of your Good Eggs account or anyone else’s (such as allowing
              someone else to log in to the Services as you);
            </li>
            <li>
              Attempts, in any manner, to obtain the password, account, or other security
              information from any other user;
            </li>
            <li>
              Violates the security of any computer network, or cracks any passwords or security
              encryption codes;
            </li>
            <li>
              Runs Maillist, Listserv, any form of auto-responder or {'"'}spam{'"'} on the Services,
              or any processes that run or are activated while you are not logged into the Services,
              or that otherwise interfere with the proper working of the Services (including by
              placing an unreasonable load on the Services’ infrastructure);{' '}
            </li>
            <li>
              {'"'}Crawls,{'"'} {'"'}scrapes,{'"'} or {'"'}spiders{'"'} any page, data, or portion
              of or relating to the Services or Content (through use of manual or automated means);
            </li>
            <li>Copies or stores any significant portion of the Content; </li>
            <li>
              Decompiles, reverse engineers, or otherwise attempts to obtain the source code or
              underlying ideas or information of or relating to the Services.
            </li>
          </ol>
          A violation of any of the foregoing is grounds for termination of your right to use or
          access the Services.
        </p>
        <h4>What are my rights in Good Eggs?</h4>
        <p>
          The materials displayed or performed or available on or through the Services, including,
          but not limited to, text, graphics, data, articles, photos, images, illustrations, User
          Submissions, and so forth (all of the foregoing, the {'"'}Content{'"'}) are protected by
          copyright and/or other intellectual property laws. You promise to abide by all copyright
          notices, trademark rules, information, and restrictions contained in any Content you
          access through the Services, and you won’t use, copy, reproduce, modify, translate,
          publish, broadcast, transmit, distribute, perform, upload, display, license, sell or
          otherwise exploit for any purpose any Content not owned by you, (i) without the prior
          consent of the owner of that Content or (ii) in a way that violates someone else’s
          (including Good Eggs’) rights.
          <br />
          <br />
          You understand that Good Eggs owns the Services. You won’t modify, publish, transmit,
          participate in the transfer or sale of, reproduce (except as expressly provided in this
          Section), create derivative works based on, or otherwise exploit any of the Services.
          <br />
          <br />
          The Services may allow you to copy or download certain Content; please remember that just
          because this functionality exists, doesn’t mean that all the restrictions above don’t
          apply – they do!
        </p>
        <h4>Do I have to grant any licenses to Good Eggs or to other users?</h4>
        <p>
          Anything you post, upload, share, store, or otherwise provide through the Services is your
          {'"'}User Submission.{'"'} Some User Submissions are viewable by other users. In order to
          display your User Submissions on the Services, and to allow other users to enjoy them
          (where applicable), you grant us certain rights in those User Submissions. Please note
          that all of the following licenses are subject to our{' '}
          <a className="terms_page__link" href={settings.privacyLink}>
            Privacy Policy
          </a>{' '}
          to the extent they relate to User Submissions that are also your personally-identifiable
          information.
          <br />
          <br />
          You agree that you will not post, upload, share, store, or otherwise provide through the
          Services any User Submissions that: (i) infringe any third party{"'"}s copyrights or other
          rights (e.g., trademark, privacy rights, etc.); (ii) contain sexually explicit content or
          pornography; (iii) contain hateful, defamatory, or discriminatory content or incite hatred
          against any individual or group; (iv) exploit minors; (v) depict unlawful acts or extreme
          violence; (vi) depict animal cruelty or extreme violence towards animals; (vii) promote
          fraudulent schemes, multi-level marketing (MLM) schemes, get rich quick schemes, online
          gaming and gambling, cash gifting, work from home businesses, or any other dubious
          money-making ventures; or (viii) that violate any law.
          <br />
          <br />
          By submitting User Submissions through the Services, you hereby do and shall grant Good
          Eggs a worldwide, non-exclusive, perpetual, royalty-free, fully paid, sublicensable and
          transferable license to use, edit, modify, truncate, aggregate, reproduce, distribute,
          prepare derivative works of, display, perform, and otherwise fully exploit the User
          Submissions in connection with this site, the Services and our (and our successors’ and
          assigns’) businesses, including without limitation for promoting and redistributing part
          or all of this site or the Services (and derivative works thereof) in any media formats
          and through any media channels (including, without limitation, third party websites and
          feeds), and including after your termination of your account or the Services. You also
          hereby do and shall grant each user of this site and/or the Services a non-exclusive,
          perpetual license to access your User Submissions through this site and/or the Services,
          and to use, edit, modify, reproduce, distribute, prepare derivative works of, display and
          perform such User Submissions, including after your termination of your account or the
          Services. For clarity, the foregoing license grants to us and our users do not affect your
          other ownership or license rights in your User Submissions, including the right to grant
          additional licenses to your User Submissions, unless otherwise agreed in writing. You
          represent and warrant that you have all rights to grant such licenses to us without
          infringement or violation of any third party rights, including without limitation, any
          privacy rights, publicity rights, copyrights, trademarks, contract rights, or any other
          intellectual property or proprietary rights.
          <br />
          <br />
          Finally, you understand and agree that Good Eggs, in performing the required technical
          steps to provide the Services to our users (including you), may need to make changes to
          your User Submissions to conform and adapt those User Submissions to the technical
          requirements of connection networks, devices, services, or media, and the foregoing
          licenses include the rights to do so.
        </p>
        <h4>What if I see something on the Services that infringes my copyright?</h4>
        <p>
          You may have heard of the Digital Millennium Copyright Act (the {'"'}DMCA{'"'}), as it
          relates to online service providers, like Good Eggs, being asked to remove material that
          allegedly violates someone’s copyright. We respect others’ intellectual property rights,
          and we reserve the right to delete or disable Content alleged to be infringing, and to
          terminate the accounts of repeat alleged infringers; to review our complete Copyright
          Dispute Policy and learn how to report potentially infringing content,{' '}
          <a className="terms_page__link" href="/terms/copyright">
            click here
          </a>
          . To learn more about the DMCA,{' '}
          <a className="terms_page__link" href="http://www.copyright.gov/legislation/dmca.pdf">
            click here
          </a>
          .
        </p>
        <h4>Who is responsible for what I see and do on the Services?</h4>
        <p>
          Any information or content publicly posted or privately transmitted through the Services
          is the sole responsibility of the person from whom such content originated, and you access
          all such information and content at your own risk, and we aren’t liable for any errors or
          omissions in that information or content or for any damages or loss you might suffer in
          connection with it. We cannot control and have no duty to take any action regarding how
          you may interpret and use the Content or what actions you may take as a result of having
          been exposed to the Content, and you hereby release us from all liability for you having
          acquired or not acquired Content through the Services. We can’t guarantee the identity of
          any users with whom you interact in using the Services and are not responsible for which
          users gain access to the Services.
          <br />
          <br />
          You are responsible for all Content you contribute, in any manner, to the Services, and
          you represent and warrant you have all rights necessary to do so, in the manner in which
          you contribute it. You will keep all your registration information accurate and current.
          You are responsible for all your activity in connection with the Services.
          <br />
          <br />
          The Services may contain links or connections to third party websites or services that are
          not owned or controlled by Good Eggs. When you access third party websites or use third
          party services, you accept that there are risks in doing so, and that Good Eggs is not
          responsible for such risks. We encourage you to be aware when you leave the Services and
          to read the terms and conditions and Privacy Policy of each third party website or service
          that you visit or utilize.
          <br />
          <br />
          Good Eggs has no control over, and assumes no responsibility for, the content, accuracy,
          privacy policies, or practices of or opinions expressed in any third party websites or by
          any third party that you interact with through the Services. In addition, Good Eggs will
          not and cannot monitor, verify, censor or edit the content of any third party site or
          service. By using the Services, you release and hold us harmless from any and all
          liability arising from your use of any third party website or service.
          <br />
          <br />
          Your interactions with organizations and/or individuals found on or through the Services,
          including payment and delivery of goods or services, and any other terms, conditions,
          warranties or representations associated with such dealings, are solely between you and
          such organizations and/or individuals. You should make whatever investigation you feel
          necessary or appropriate before proceeding with any online or offline transaction with any
          of these third parties. You agree that Good Eggs shall not be responsible or liable for
          any loss or damage of any sort incurred as the result of any such dealings.
          <br />
          <br />
          If there is a dispute between participants on the Services, or between users and any third
          party, you agree that Good Eggs is under no obligation to become involved. In the event
          that you have a dispute with one or more other users, you release Good Eggs, its officers,
          employees, agents, and successors from claims, demands, and damages of every kind or
          nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out
          of or in any way related to such disputes and/or our Services. If you are a California
          resident, you shall and hereby do waive California Civil Code Section 1542, which says in
          substance: {'"'}A general release does not extend to claims which the creditor or
          releasing party does not know or suspect to exist in his or her favor at the time of
          executing the release and that, if known by him or her, would have materially affected his
          or her settlement with the debtor or released party.{'"'}
        </p>
        <h4>Will Good Eggs ever change the Services?</h4>
        <p>
          We’re always trying to improve the Services, so they may change over time. We may suspend
          or discontinue any part of the Services, or we may introduce new features or impose limits
          on certain features or restrict access to parts or all of the Services. We’ll try to give
          you notice when we make a material change to the Services that would adversely affect you,
          but this isn’t always practical. Similarly, we reserve the right to remove any Content
          from the Services at any time, for any reason (including, but not limited to, if someone
          alleges you contributed that Content in violation of these Terms), in our sole discretion,
          and without notice.
        </p>
        <h4>Does Good Eggs cost anything?</h4>
        <p>
          You may choose to purchase Products through the Services and such Products are offered at
          the prices set forth on our goodeggs.com website or our mobile applications. Good Eggs may
          limit or cancel quantities of Products purchased, and it reserves the right to refuse any
          order. In the event Good Eggs needs to make a change to an order, it will attempt to
          notify you by contacting the email address, billing address, and/or phone number provided
          at the time the order was made. The prices displayed are quoted in U.S. currency and are
          valid only in the United States. Prices are subject to change at any time. Sales tax will
          be determined by the shipping address of the order and will automatically be added to the
          order. Good Eggs is required by law to apply sales tax to orders to certain states.
          Purchased Products will be shipped to the shipping address of the order. Information about
          our shipping rates, return policy and limited warranty can all be found on our{' '}
          <a className="terms_page__link" href="https://help.goodeggs.com/hc/en-us">
            Help Center
          </a>
          . Any payment terms presented to you in the process of purchasing Products are deemed part
          of these Terms.
          <ol type="a">
            <li>
              <u>Billing.</u> We use a third-party payment processor (the {'"'}
              Payment Processor{'"'}) to bill you through a payment account linked to your account
              on the Services (your {'"'}Billing Account{'"'}). The processing of payments will be
              subject to the terms, conditions and privacy policies of the Payment Processor in
              addition to these Terms. Currently, we use Stripe, Inc. as our Payment Processor. You
              can access Stripe’s Terms of Service at{' '}
              <a className="terms_page__link" href="https://stripe.com/us/checkout/legal">
                https://stripe.com/us/checkout/legal
              </a>{' '}
              and their Privacy Policy at{' '}
              <a className="terms_page__link" href="https://stripe.com/us/privacy">
                https://stripe.com/us/privacy
              </a>
              . We are not responsible for any error by, or other acts or omissions of, the Payment
              Processor. By choosing to purchase Products, you agree to pay us, through the Payment
              Processor, all charges at the prices then in effect for any such Products in
              accordance with the applicable payment terms, and you authorize us, through the
              Payment Processor, to charge your chosen payment provider (your {'"'}Payment Method
              {'"'}). You agree to make payment using that selected Payment Method. We reserve the
              right to correct any errors or mistakes that the Payment Processor makes even if it
              has already requested or received payment.{' '}
            </li>
            <li>
              <u>Payment Method.</u> The terms of your payment will be based on your Payment Method
              and may be determined by agreements between you and the financial institution, credit
              card issuer or other provider of your chosen Payment Method. If we, through the
              Payment Processor, do not receive payment from you, you agree to pay all amounts due
              on your Billing Account upon demand.{' '}
            </li>
            <li>
              <u>Recurring Billing.</u> Some of the Products may be sold as a subscription,
              consisting of an initial period for which there is a one-time charge, followed by
              recurring period charges for additional Products as agreed to by you ({'"'}Paid
              Subscription{'"'}). By choosing a Paid Subscription, you acknowledge that such
              Services have an initial and recurring payment feature and you accept responsibility
              for all recurring charges prior to cancellation. WE MAY SUBMIT PERIODIC CHARGES (E.G.,
              WEEKLY) WITHOUT FURTHER AUTHORIZATION FROM YOU, UNTIL YOU PROVIDE PRIOR NOTICE
              (RECEIPT OF WHICH IS CONFIRMED BY US) THAT YOU HAVE TERMINATED THIS AUTHORIZATION OR
              WISH TO CHANGE YOUR PAYMENT METHOD. SUCH NOTICE WILL NOT AFFECT CHARGES SUBMITTED
              BEFORE WE REASONABLY COULD ACT. TO TERMINATE YOUR AUTHORIZATION OR CHANGE YOUR PAYMENT
              METHOD, GO TO ACCOUNT SETTINGS.
            </li>
            <li>
              <u>Current Information Required.</u> YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE
              INFORMATION FOR YOUR BILLING ACCOUNT. YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP
              YOUR BILLING ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH AS A CHANGE IN BILLING
              ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE), AND YOU MUST PROMPTLY
              NOTIFY US OR OUR PAYMENT PROCESSOR IF YOUR PAYMENT METHOD IS CANCELED (E.G., FOR LOSS
              OR THEFT) OR IF YOU BECOME AWARE OF A POTENTIAL BREACH OF SECURITY, SUCH AS THE
              UNAUTHORIZED DISCLOSURE OR USE OF YOUR USER NAME OR PASSWORD. CHANGES TO SUCH
              INFORMATION CAN BE MADE AT ACCOUNT SETTINGS. IF YOU FAIL TO PROVIDE ANY OF THE
              FOREGOING INFORMATION, YOU AGREE THAT WE MAY CONTINUE CHARGING YOU FOR ANY USE OF PAID
              SUBSCRIPTIONS UNDER YOUR BILLING ACCOUNT UNLESS YOU HAVE TERMINATED YOUR PAID
              SUBSCRIPTIONS AS SET FORTH ABOVE.
            </li>
            <li>
              <u>Change in Amount Authorized.</u> If the amount to be charged to your Billing
              Account varies from the amount you preauthorized (other than due to the imposition or
              change in the amount of state sales taxes), you have the right to receive, and we
              shall provide, notice of the amount to be charged and the date of the charge before
              the scheduled date of the transaction. Any agreement you have with your payment
              provider will govern your use of your Payment Method. You agree that we may accumulate
              charges incurred and submit them as one or more aggregate charges, including during or
              at the end of each billing cycle.
            </li>
            <li>
              <u>Auto-Renewal for Paid Subscriptions.</u> Unless you opt out of auto-renewal, which
              can be done through your account settings, any Paid Subscription(s) you have signed up
              for will be automatically extended for successive renewal periods of the same duration
              as the subscription term originally selected, at the then-current non-promotional
              rate. To change or resign your Paid Subscription(s) at any time, go to account
              settings. If you terminate a Paid Subscription, you may use your subscription until
              the end of your then-current term, and your subscription will not be renewed after
              your then-current term expires. However, you will not be eligible for a prorated
              refund of any portion of the subscription fee paid for the then-current subscription
              period. If you do not want to continue to be charged on a recurring monthly basis, you
              must cancel the applicable Paid subscription through your account settings or
              terminate your Good Eggs account before the end of the recurring term. Paid
              subscriptions cannot be terminated before the end of the period for which you have
              already paid, and except as expressly provided in these terms, Good Eggs will not
              refund any fees that you have already paid.{' '}
            </li>
            <li>
              <u>Reaffirmation of Authorization.</u> Your non-termination or continued use of a Paid
              Subscription reaffirms that we are authorized to charge your Payment Method for that
              Paid Subscription. We may submit those charges for payment and you will be responsible
              for such charges. This does not waive our right to seek payment directly from you.
              Your charges may be payable in advance, in arrears, per usage, or as otherwise
              described when you initially selected to use the Paid Subscription.]{' '}
            </li>
            <li>
              <u>Free Trials and Other Promotions.</u> Any free trial or other promotion that
              provides access to free Products or free Paid Subscriptions must be used within the
              specified time of the trial. You must stop using a Paid Subscription before the end of
              the trial period in order to avoid being charged for that Paid Subscription. If you
              cancel prior to the end of the trial period and are inadvertently charged for a Paid
              Subscription, please contact us at{' '}
              <a className="terms_page__link" href="mailto:help@goodeggs.com">
                help@goodeggs.com
              </a>
              .
            </li>
          </ol>
        </p>
        <h4>What if I Want a Refund?</h4>
        <p>
          If you have any requests for order cancellations, refunds, or returns, please visit our
          <a className="terms_page__link" href="https://help.goodeggs.com/hc/en-us">
            Help Center
          </a>{' '}
          articles for our policies regarding the same, or contact us at
          <a className="terms_page__link" href="mailto:help@goodeggs.com">
            help@goodeggs.com
          </a>{' '}
          for assistance. As a reminder, these Terms include the provisions in the{' '}
          <a className="terms_page__link" href="/terms/returns">
            Returns, Refunds, and Credits Policy
          </a>
          ,{' '}
          <a className="terms_page__link" href="/terms/gift-cards">
            Gift Cards Terms of Use
          </a>
          ,{' '}
          <a className="terms_page__link" href="/terms/referral">
            Promotions and Referral Program Terms of Use
          </a>
          , and{' '}
          <a className="terms_page__link" href="/terms/alcohol">
            Alcohol Terms of Use
          </a>
          .
        </p>
        <h4>What if I want to stop using Good Eggs?</h4>
        <p>
          You’re free to do that at any time, by contacting us at{' '}
          <a className="terms_page__link" href="mailto:help@goodeggs.com">
            help@goodeggs.com
          </a>
          ; please refer to our{' '}
          <a className="terms_page__link" href={settings.privacyLink}>
            Privacy Policy
          </a>{' '}
          , as well as the licenses above, to understand how we treat information you provide to us
          after you have stopped using our Services.
          <br />
          <br />
          Good Eggs is also free to terminate (or suspend access to) your use of the Services or
          your account, for any reason in our discretion, including your breach of these Terms. Good
          Eggs has the sole right to decide whether you are in violation of any of the restrictions
          set forth in these Terms.
          <br />
          <br />
          Account termination may result in destruction of any Content associated with your account,
          so keep that in mind before you decide to terminate your account. We will try to provide
          advance notice to you prior to our terminating your account so that you are able to
          retrieve any important User Submissions you may have stored in your account (to the extent
          allowed by law and these Terms), but we may not do so if we determine it would be
          impractical, illegal, not in the interest of someone’s safety or security, or otherwise
          harmful to the rights or property of Good Eggs.
          <br />
          <br />
          Provisions that, by their nature, should survive termination of these Terms shall survive
          termination. By way of example, all of the following will survive termination: any
          obligation you have to pay us or indemnify us, any limitations on our liability, any terms
          regarding ownership or intellectual property rights, and terms regarding disputes between
          us.
        </p>
        <h4>What about Mobile Applications and In-App Purchases?</h4>
        <p>
          You acknowledge and agree that the availability of our mobile application is dependent on
          the third party stores from which you download the application, e.g., the App Store from
          Apple or the Android app market from Google (each an {'"'}App Store{'"'}). Each App Store
          may have its own terms and conditions to which you must agree before downloading mobile
          applications from such store, including the specific terms relating to Apple App Store set
          forth below. You agree to comply with, and your license to use our application is
          conditioned upon your compliance with, such App Store terms and conditions. To the extent
          such other terms and conditions from such App Store are less restrictive than, or
          otherwise conflict with, the terms and conditions of these Terms of Use, the more
          restrictive or conflicting terms and conditions in these Terms of Use apply.
          <br />
          <br />
          <b>
            <u>
              Through our mobile applications, you may purchase certain Products [or features
              designed to enhance the performance of the Services] ({'"'}In-App Purchase{'"'}). When
              you make an In-App Purchase, you are doing so through either the Apple iTunes service
              or the Google Play service and you are agreeing to their respective Terms and
              Conditions, available at{' '}
              <a
                className="terms_page__link"
                href="http://www.apple.com/legal/internet-services/itunes/us/terms.html"
              >
                http://www.apple.com/legal/internet-services/itunes/us/terms.html
              </a>{' '}
              and{' '}
              <a
                className="terms_page__link"
                href="http://play.google.com/intl/en_us/about/play-terms.html"
              >
                http://play.google.com/intl/en_us/about/play-terms.html
              </a>
              . Good Eggs is not a party to any In-App Purchase.
            </u>
          </b>
        </p>
        <h4>
          I use the Good Eggs App available via the Apple App Store – should I know anything about
          that?
        </h4>{' '}
        <p>
          These Terms apply to your use of all the Services, including our iOS applications
          available via the Apple, Inc. ({'"'}Apple{'"'}) App Store (the {'"'}
          Application{'"'}), but the following additional terms also apply to the Application:
          <ol type="a">
            <li>
              Both you and Good Eggs acknowledge that the Terms are concluded between you and Good
              Eggs only, and not with Apple, and that Apple is not responsible for the Application
              or the Content;
            </li>
            <li>
              The Application is licensed to you on a limited, non-exclusive, non-transferrable,
              non-sublicensable basis, solely to be used in connection with the Services for your
              private, personal, non-commercial use, subject to all the terms and conditions of
              these Terms as they are applicable to the Services;{' '}
            </li>
            <li>
              You will only use the Application in connection with an Apple device that you own or
              control;
            </li>
            <li>
              You acknowledge and agree that Apple has no obligation whatsoever to furnish any
              maintenance and support services with respect to the Application;{' '}
            </li>
            <li>
              In the event of any failure of the Application to conform to any applicable warranty,
              including those implied by law, you may notify Apple of such failure; upon
              notification, Apple’s sole warranty obligation to you will be to refund to you the
              purchase price, if any, of the Application;{' '}
            </li>
            <li>
              You acknowledge and agree that Good Eggs, and not Apple, is responsible for addressing
              any claims you or any third party may have in relation to the Application;{' '}
            </li>
            <li>
              You acknowledge and agree that, in the event of any third party claim that the
              Application or your possession and use of the Application infringes that third party’s
              intellectual property rights, Good Eggs, and not Apple, will be responsible for the
              investigation, defense, settlement and discharge of any such infringement claim;{' '}
            </li>
            <li>
              You represent and warrant that you are not located in a country subject to a U.S.
              Government embargo, or that has been designated by the U.S. Government as a {'"'}
              terrorist supporting{'"'} country, and that you are not listed on any U.S. Government
              list of prohibited or restricted parties;{' '}
            </li>
            <li>
              Both you and Good Eggs acknowledge and agree that, in your use of the Application, you
              will comply with any applicable third party terms of agreement which may affect or be
              affected by such use; and{' '}
            </li>
            <li>
              Both you and Good Eggs acknowledge and agree that Apple and Apple’s subsidiaries are
              third party beneficiaries of these Terms, and that upon your acceptance of these
              Terms, Apple will have the right (and will be deemed to have accepted the right) to
              enforce these Terms against you as the third party beneficiary hereof.
            </li>
          </ol>
        </p>
        <h4>Can I refer other users?</h4>
        <p>
          From time to time Good Eggs may offer rewards or incentives for referring others to the
          Services. For details of any current referral offers, please see our{' '}
          <a className="terms_page__link" href="/refer-a-friend">
            referral page
          </a>
          . The referring user ({'"'}Referrer{'"'}) may refer individuals or entities who are
          neither current customers of Good Eggs nor registered users of the Services ({'"'}Referee
          {'"'}). A registered user is a person or entity that already has an existing account with
          Good Eggs. There is no limit to the number of referrals that Referrer can make, nor the
          cumulative rewards or incentives that the Referrer may receive through such special
          offers, unless otherwise indicated. Referrer will receive the stated reward or incentive
          for each Referee sent by the Referrer that completes the required action described in that
          specific offer (such as signing up for an account or making a purchase). All Referees must
          be first-time recipients of the offer, and multiple referrals to the same individual or
          entity will be disregarded. Good Eggs reserves the right to modify or terminate any
          special offers at any time and to revoke from Referrer and Referee the special offer at
          Good Eggs{"'"} discretion for any reason or for no reason whatsoever. If Good Eggs
          determines that Referrer or Referee is attempting to obtain unfair advantage or otherwise
          violate the terms or spirit of such special offer, Good Eggs reserves the right to (a)
          revoke any rewards or incentives issued to either Referrer or Referee and/or (b) charge
          the Referrer or Referee for any rewards or incentives (1) used by Referrer or Referee
          prior to such revocation or (2) issued by Good Eggs to any ineligible Referrer or Referee.
          All special offers are subject to any other terms, conditions and restrictions set forth
          on the Services or presented in connection with the special offer.
        </p>
        <h4>What else do I need to know?</h4>
        <p>
          <u>
            <i>Warranty Disclaimer.</i>
          </u>{' '}
          Neither Good Eggs nor its licensors or suppliers makes any representations or warranties
          concerning any content contained in or accessed through the Services, and we will not be
          responsible or liable for the accuracy, copyright compliance, legality, or decency of
          material contained in or accessed through the Services. We (and our licensors and
          suppliers) make no representations or warranties regarding suggestions or recommendations
          of services or products offered or purchased through or in connection with the Services
          including, without limitation, any Products. Products and services purchased or offered
          (whether or not following such recommendations and suggestions) through the Services are
          provided {'"'}AS IS{'"'} and without any warranty of any kind from Good Eggs or others
          (unless, with respect to such others only, provided expressly and unambiguously in writing
          by a designated third party for a specific product). THE PRODUCTS, SERVICES AND CONTENT
          ARE PROVIDED BY GOOD EGGS (AND ITS LICENSORS AND SUPPLIERS) ON AN {'"'}AS-IS{'"'} BASIS,
          WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION,
          IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT,
          OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW
          LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY
          TO YOU.
          <br />
          <br />
          <u>
            <i>Limitation of Liability</i>
          </u>
          . TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO
          LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR
          OTHERWISE) SHALL GOOD EGGS (OR ITS LICENSORS OR SUPPLIERS) BE LIABLE TO YOU OR TO ANY
          OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY
          KIND, INCLUDING DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF
          RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE GOODS, SERVICES OR
          TECHNOLOGY, OR (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF (I) $100 OR
          (II) THE AMOUNTS PAID OR PAYABLE BY YOU TO GOOD EGGS IN CONNECTION WITH THE SERVICES IN
          THE TWELVE (12) MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM, OR (D) ANY MATTER BEYOND OUR
          REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN
          DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
          <br />
          <br />
          <u>
            <i>Indemnity.</i>
          </u>{' '}
          To the fullest extent allowed by applicable law, You agree to indemnify and hold Good
          Eggs, its affiliates, officers, agents, employees, and partners harmless from and against
          any and all claims, liabilities, damages (actual and consequential), losses and expenses
          (including attorneys’ fees) arising from or in any way related to any third party claims
          relating to (a) your use of the Services (including any actions taken by a third party
          using your account), and (b) your violation of these Terms. In the event of such a claim,
          suit, or action ({'"'}Claim{'"'}), we will attempt to provide notice of the Claim to the
          contact information we have for your account (provided that failure to deliver such notice
          shall not eliminate or reduce your indemnification obligations hereunder).
          <br />
          <br />
          <u>
            <i>Assignment.</i>
          </u>{' '}
          You may not assign, delegate or transfer these Terms or your rights or obligations
          hereunder, or your Services account, in any way (by operation of law or otherwise) without
          Good Eggs’ prior written consent. We may transfer, assign, or delegate these Terms and our
          rights and obligations without consent.
          <br />
          <br />
          <u>
            <i>Choice of Law.</i>
          </u>{' '}
          These Terms are governed by and will be construed under the Federal Arbitration Act,
          applicable federal law, and the laws of the State of California, without regard to the
          conflicts of laws provisions thereof.
          <br />
          <br />
          <u>
            <i>Arbitration Agreement.</i>
          </u>{' '}
          Please read the following ARBITRATION AGREEMENT carefully because it requires you to
          arbitrate certain disputes and claims with Good Eggs and limits the manner in which you
          can seek relief from Good Eggs. Both you and Good Eggs acknowledge and agree that for the
          purposes of any dispute arising out of or relating to the subject matter of these Terms,
          Good Eggs
          {"'"}
          officers, directors, employees and independent contractors ({'"'}Personnel{'"'}) are
          third-party beneficiaries of these Terms, and that upon your acceptance of these Terms,
          Personnel will have the right (and will be deemed to have accepted the right) to enforce
          these Terms against you as the third-party beneficiary hereof.
          <br />
          <br />
          (a) <i>Arbitration Rules; Applicability of Arbitration Agreement.</i> The parties shall
          use their best efforts to settle any dispute, claim, question, or disagreement arising out
          of or relating to the subject matter of these Terms directly through good-faith
          negotiations, which shall be a precondition to either party initiating arbitration. If
          such negotiations do not resolve the dispute, it shall be finally settled by binding
          arbitration in San Francisco County, California. The arbitration will proceed in the
          English language, in accordance with the JAMS Streamlined Arbitration Rules and Procedures
          (the {'"'}Rules{'"'}) then in effect, by one commercial arbitrator with substantial
          experience in resolving intellectual property and commercial contract disputes. The
          arbitrator shall be selected from the appropriate list of JAMS arbitrators in accordance
          with such Rules. Judgment upon the award rendered by such arbitrator may be entered in any
          court of competent jurisdiction.
          <br />
          <br />
          (b) <i>Costs of Arbitration.</i> The Rules will govern payment of all arbitration fees.
          Good Eggs will pay all arbitration fees for claims less than seventy-five thousand
          ($75,000) dollars. Good Eggs will not seek its attorneys’ fees and costs in arbitration
          unless the arbitrator determines that your claim is frivolous.
          <br />
          <br />
          (c) <i>Small Claims Court;</i> Infringement. Either you or Good Eggs may assert claims, if
          they qualify, in small claims court in San Francisco County, California or any United
          States county where you live or work. Furthermore, notwithstanding the foregoing
          obligation to arbitrate disputes, each party shall have the right to pursue injunctive or
          other equitable relief at any time, from any court of competent jurisdiction, to prevent
          the actual or threatened infringement, misappropriation or violation of a party{"'"}s
          copyrights, trademarks, trade secrets, patents or other intellectual property rights.
          <br />
          <br />
          (d) <i>Waiver of Jury Trial.</i> YOU AND GOOD EGGS WAIVE ANY CONSTITUTIONAL AND STATUTORY
          RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. You and Good Eggs are
          instead choosing to have claims and disputes resolved by arbitration. Arbitration
          procedures are typically more limited, more efficient, and less costly than rules
          applicable in court and are subject to very limited review by a court. In any litigation
          between you and Good Eggs over whether to vacate or enforce an arbitration award, YOU AND
          GOOD EGGS WAIVE ALL RIGHTS TO A JURY TRIAL, and elect instead to have the dispute be
          resolved by a judge.
          <br />
          <br />
          (e) <i>Waiver of Class or Consolidated Actions.</i>
          ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED
          OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE
          CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF
          ANY OTHER CUSTOMER OR USER. If however, this waiver of class or consolidated actions is
          deemed invalid or unenforceable, neither you nor Good Eggs is entitled to arbitration;
          instead all claims and disputes will be resolved in a court as set forth in (g) below.
          <br />
          <br />
          (f) <i>Opt-out.</i> You have the right to opt out of the provisions of this Section by
          sending written notice of your decision to opt out to the following address: Good Eggs
          Inc., 2000 Maritime St. Oakland, CA 94607 postmarked within thirty (30) days of first
          accepting these Terms. You must include (i) your name and residence address, (ii) the
          email address and/or telephone number associated with your account, and (iii) a clear
          statement that you want to opt out of these Terms’ arbitration agreement.
          <br />
          <br />
          (g) <i>Exclusive Venue.</i> If you send the opt-out notice in (f), and/or in any
          circumstances where the foregoing arbitration agreement permits either you or Good Eggs to
          litigate any dispute arising out of or relating to the subject matter of these Terms in
          court, then the foregoing arbitration agreement will not apply to either party, and both
          you and Good Eggs agree that any judicial proceeding (other than small claims actions)
          will be brought in the state or federal courts located in, respectively, San Francisco
          County, California, or the federal district in which that county falls.
          <br />
          <br />
          (h) <i>Severability.</i> If the prohibition against class actions and other claims brought
          on behalf of third parties contained above is found to be unenforceable, then all of the
          preceding language in this Arbitration Agreement section will be null and void. This
          arbitration agreement will survive the termination of your relationship with Good Eggs.
          <br />
          <br />
          <u>
            <i>Miscellaneous.</i>
          </u>{' '}
          You will be responsible for paying, withholding, filing, and reporting all taxes, duties,
          and other governmental assessments associated with your activity in connection with the
          Services, provided that Good Eggs may, in its sole discretion, do any of the foregoing on
          your behalf or for itself as it sees fit. The failure of either you or us to exercise, in
          any way, any right herein shall not be deemed a waiver of any further rights hereunder. If
          any provision of these Terms is found to be unenforceable or invalid, that provision will
          be limited or eliminated, to the minimum extent necessary, so that these Terms shall
          otherwise remain in full force and effect and enforceable. You and Good Eggs agree that
          these Terms are the complete and exclusive statement of the mutual understanding between
          you and Good Eggs, and that it supersedes and cancels all previous written and oral
          agreements, communications and other understandings relating to the subject matter of
          these Terms. You hereby acknowledge and agree that you are not an employee, agent,
          partner, or joint venture of Good Eggs, and you do not have any authority of any kind to
          bind Good Eggs in any respect whatsoever. Except as expressly set forth in the section
          above regarding the Apple Application you and Good Eggs agree there are no third party
          beneficiaries intended under these Terms.
        </p>
      </div>
    );
  }

  public render(): ReactElement {
    return (
      <MarketLayout disableUpcomingOrdersBanner>
        <Helmet>
          <title>Terms of Use | Good Eggs</title>
        </Helmet>
        <TermsLayout currentSlug="terms-of-use">{this.renderTermsOfUse()}</TermsLayout>
      </MarketLayout>
    );
  }
}

export default connect((state) => state)(TermsPage);
